@import "src/styles/variables";

.addContentContainer {}

.searchContainer {
    padding: 10px;
    justify-content: space-between;

    border-bottom: 2px solid #EBEBEB;
}

.show-more{
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;

    span{
        display: inline-block;
        cursor: pointer;
        padding: 8px 16px;
        border-radius: $border-radius;
        border: 1px solid $modest-color;
        color: $font-color-modest;

        &:hover {
            color: black;
            border-color: $primary-color;
        }
    }
}