@import "/src/styles/variables.scss";

.loader_container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.root {
    min-width: 55vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .general_settigns {
        margin-bottom: 16px;
    }

    .titles_style {
        margin-bottom: .3rem;
    }

    .search_container {
        padding-bottom: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        border-bottom: 2px solid #EBEBEB;

        .dropdown {
            width: 250px;
        }

        label {
            margin: 15px 0;
        }
    }

    .terminal_container{
        margin-bottom: 10px;
        overflow-y: auto;
        padding-bottom: 10px;
        height: calc(60vh - 135px);

        border-bottom: 2px solid #EBEBEB;

        .terminal_item {
            cursor: pointer;
            padding: 5px 0;
            border-bottom: 1px solid $modest-color;
            display: flex;
            align-items: center;

            &:hover {
                background: #80808014;
            }
        }

        .name_container {
            width: 100%;
            display: flex;
            justify-content: space-between;

            .name_style {
                padding-left: .7rem;
            }

            .terminal_checkbox {
                padding-right: .7rem;
            }
        }
        .show_more {
            margin-top: 10px;
            display: flex;
            justify-content: center;
    
            span {
                display: inline-block;
                cursor: pointer;
                padding: 8px 16px;
                border-radius: $border-radius;
                border: 1px solid $modest-color;
                color: $font-color-modest;
    
                &:hover {
                    color: black;
                    border-color: $primary-color;
                }
            }
        }
    }

    .footer_container {
        .selection_stats {
            float: left;
            display: block;
            
            span {
                display: block;
                text-align: left;
                color: #ADADAD;
            }
        }

        .button_container {
            display: flex;
            justify-content: flex-end;
            padding-bottom: 5px;
            height: 50px;
    
            >* {
                margin: 0 5px;
            }
        }
    }
}