@import "/src/styles/variables.scss";

.root {
    .message {
        font-size: 16px;
        padding-inline-end: 50px;
        padding-bottom: 50px;
    }

    .buttons_container {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 10px;
        margin-inline-start: 100px;
        margin-top: 16px;

        button {
            padding: 8px 16px;
            height: auto;
        }
    }

    .without_edit_buttons_container {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 10px;
        margin-inline-start: 100px;
        margin-top: 16px;

        button {
            padding: 8px 16px;
            height: auto;
        }
    }
}