@import "src/styles/variables";

.addPlaylistItems {
    min-width: 55vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .searchContainer {
        padding-top: 10px;
        padding-bottom: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    
        border-bottom: 2px solid #EBEBEB;
    }

    .switchControlPanel {
        display: flex;
        height: 65px;
        border: 1px solid $modest-color;
        border-radius: $border-radius;

        >* {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50%;
            padding: 20px;
            cursor: pointer;
            background-color: $modest-color;
            color: $font-color-modest;

            &.active {
                color: $primary-color;
                background-color: white;
            }
        }
    }

    .addPlaylistContentContainer {
        margin-bottom: 10px;
        overflow-y: auto;
        padding-bottom: 10px;
        height: calc(60vh - 135px);

        border-bottom: 2px solid #EBEBEB;

        .add-item-container {
            cursor: pointer;
            padding: 5px 0;
            margin-bottom: 5px;
            border-bottom: 1px solid $modest-color;
            display: flex;
            align-items: center;

            .preview-container {
                margin-inline-end: 10px;

                img {
                    width: 45px;
                    height: 45px;
                    border-radius: $border-radius;
                }
            }

            .name-container {
                width: 100%;
                display: flex;
                justify-content: space-between;
            }
        }

        .show-more{
            margin-top: 10px;
            display: flex;
            justify-content: center;

            span{
                display: inline-block;
                cursor: pointer;
                padding: 8px 16px;
                border-radius: $border-radius;
                border: 1px solid $modest-color;
                color: $font-color-modest;

                &:hover {
                    color: black;
                    border-color: $primary-color;
                }
            }
        }
    }

    .footer-container {
        .selectionStats {
            float: left;
            display: block;
            
            span {
                display: block;
                text-align: left;
                color: #ADADAD;
            }
        }

        .buttons-container {
            display: flex;
            justify-content: flex-end;
            padding-bottom: 5px;
            height: 50px;
    
            >* {
                margin: 0 5px;
            }
        }
    }
}